











import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VideoYoutube',
  props: {
    videoOrientation: {
      type: String,
    },
    caption: {
      type: String,
    },
    youtubeUrl: {
      type: String,
    },
  },
  setup(props) {
    return {
      orientationClass: computed(() => props.videoOrientation?.toLowerCase() || ''),
    };
  },
});
